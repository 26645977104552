<template>
  <div>
    <v-container>
      <perseu-card title="Oligoelementos">
        <template slot="title-right">
          <v-btn @click="create" color="secondary"> Novo Oligoelemento </v-btn>
        </template>
        <template slot="content">
          <filters-panel>
            <v-row align="center">
              <v-col cols="2">
                <v-switch
                  v-model="query.active"
                  label="Ativos"
                  @change="find"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="query.name"
                  label="Nome"
                  filled
                  hide-details
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="query.type"
                  label="Tipo"
                  filled
                  hide-details
                />
              </v-col>
              <v-col cols="2">
                <v-btn large color="secondary" @click="find"> Buscar </v-btn>
              </v-col>
            </v-row>
          </filters-panel>
          <v-data-table :items="items" :headers="headers" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <table-action-button
                text="Editar"
                @click="edit(item)"
                icon="edit"
              />
              <table-action-button
                v-if="item.active"
                text="Desativar"
                @click="activeOrDeactivate(item, false)"
                icon="delete"
              />
              <table-action-button
                v-else
                text="Ativar"
                @click="activeOrDeactivate(item, true)"
                icon="check"
              />
            </template>
          </v-data-table>
          <list-oligoelements-type class="mt-3" />
        </template>
      </perseu-card>
    </v-container>
    <form-oligoelements ref="form" @close="save" />
  </div>
</template>

<script>
import {
  getAll,
  getOne,
  update,
  create,
  changeOrder,
} from "@/services/oligoelements-service.js";

export default {
  name: "Oligoelements",
  components: {
    FormOligoelements: () => import("@/components/Oligoelements/Form"),
    ListOligoelementsType: () => import("@/components/Oligoelements/ListTypes"),
  },
  data: () => ({
    items: [],
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        width: 150,
        sortable: false,
        align: "start",
      },
    ],
    query: {
      active: true,
    },
  }),
  created() {
    this.find();
  },
  methods: {
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getAll(this.query);
        this.items = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        await update(item);
        const index = this.findIndexItems(item);
        this.items.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async edit(item) {
      this.$store.dispatch("loading/openDialog");
      try {
        const { data } = await getOne(item.id);
        this.$refs.form.edit(data);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    create() {
      this.$refs.form.create();
    },
    async save(flowType) {
      try {
        this.$store.dispatch("loading/openDialog");
        if (flowType.id) {
          await update(flowType);
          const index = this.findIndexItems(flowType);
          this.items.splice(index, 1, flowType);
        } else {
          const { data } = await create(flowType);
          this.items.push(data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    findIndexItems(itemSelected) {
      return this.items.findIndex((item) => item.id === itemSelected.id);
    },
    async changeOrder({ item, direction }) {
      try {
        await changeOrder({ oligoelement: item, direction });
        await this.find();
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>

<style></style>
